<template>
    <CModal
      :title="title"
      :color="color"
      size="lg"
      fullscreen="sm"
      :show.sync="roadModal"
      style="width: 100%"
    >   
      <CRow style="padding-bottom: 10px; font-size: 12px;">
        <CCol md="12">
          <ul class="nav nav-tabs justify-content-begin">
            <li @click="changeTab('info')" class="nav-item">
              <a class="nav-link" :class="tab == 'info' ? 'active' : ''" aria-current="page">General</a>
            </li>
            <li @click="changeTab('walksides')" class="nav-item">
              <a class="nav-link" :class="tab == 'walksides' ? 'active' : ''">Banquetas</a>
            </li>
            <li @click="changeTab('ways')" class="nav-item">
              <a class="nav-link" :class="tab == 'ways' ? 'active' : ''">Calles</a>
            </li>
            <li @click="changeTab('lamps')" class="nav-item">
              <a class="nav-link" :class="tab == 'lamps' ? 'active' : ''">Luminarias</a>
            </li>
            <li @click="changeTab('trees')" class="nav-item">
              <a class="nav-link" :class="tab == 'trees' ? 'active' : ''">Arboles</a>
            </li>
            <li @click="changeTab('waters')" class="nav-item">
              <a class="nav-link" :class="tab == 'waters' ? 'active' : ''">Agua</a>
            </li>
            <li @click="changeTab('sewers')" class="nav-item">
              <a class="nav-link" :class="tab == 'sewers' ? 'active' : ''">Drenaje</a>
            </li>
          </ul>
        </CCol>
      </CRow>

      <CRow v-if="tab == 'info'">
        <CCol md="12">
          <CForm>   
            <CRow>
              <CCol md="12">
                <br />
                <CRow style="font-size: 12px;">
                  <CCol sm="12">
                    <div role="group" class="form-group" style="margin: -10px 0px 10px">
                      <label>Vialidad</label>
                      <CSelect
                        :value.sync="form.road_id" 
                        :options="roadOptions"
                        :isValid="checkIfValid('road_id')"
                        placeholder=""
                        autocomplete="given-road_id"
                        invalidFeedback="Este campo es requerido."
                        @change="changeRoad"
                      />                        
                    </div>
                  </CCol>
                  <CCol sm="8">
                    <CInput
                      label="Nombre"
                      style="padding-top: 5px;"
                      :lazy="false"
                      v-model="form.name"
                      placeholder=""
                      :isValid="checkIfValid('name')"
                      placeholder=""
                      autocomplete="given-name"
                      invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
                    />
                  </CCol>
                  <CCol sm="4">
                    <div role="group" class="form-group" style="padding-top: 5px;">
                      <label> Estado </label>
                      <select v-model="form.status" class="form-control">
                          <option value="bueno">BUENO</option>
                          <option value="regular">REGULAR</option>
                          <option value="malo">MALO</option>
                      </select>
                    </div>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </CForm>
        </CCol>
      </CRow>
      <CRow v-if="tab == 'walksides'">
        <CCol md="12">
          <CForm>   
            <CRow>
              <CCol md="12">
                <CRow style="font-size: 12px;">
                  <CCol sm="12">
                    <div role="group" class="form-group" style="padding-top: 5px;">
                      <label> Banqueta 1 </label>
                      <select v-model="b1.check" class="form-control" style="font-size: 12px;">
                          <option value="1">SI</option>
                          <option value="0">NO</option>
                      </select>
                    </div>
                  </CCol>
                  <CCol sm="6">
                    <CInput
                      label="Largo (metros)"
                      style="padding-top: 5px;"
                      :lazy="false"
                      v-model="b1.length"
                      placeholder=""
                      :disabled="b1.check == 0"
                      />
                  </CCol>
                  <CCol sm="6">
                    <CInput
                      label="Ancho (metros)"
                      style="padding-top: 5px;"
                      :lazy="false"
                      v-model="b1.width"
                      placeholder=""
                      :disabled="b1.check == 0"
                      />
                  </CCol>
                  <CCol sm="6">
                    <div role="group" class="form-group" style="padding-top: 5px;">
                      <label> Rampas </label>
                      <select v-model="b1.ramps" class="form-control" :disabled="b1.check == 0">
                          <option value="1">SI</option>
                          <option value="0">NO</option>
                      </select>
                    </div>
                  </CCol>
                  <CCol sm="6">
                    <div role="group" class="form-group" style="padding-top: 5px;">
                      <label> Estado </label>
                      <select v-model="b1.status" class="form-control" :disabled="b1.check == 0">
                          <option value="bueno">BUENO</option>
                          <option value="regular">REGULAR</option>
                          <option value="malo">MALO</option>
                      </select>
                    </div>
                  </CCol>
                </CRow>
                <CRow v-if="b1.check == 1" style="font-size: 12px;"><CCol><hr /></CCol></CRow>
                <CRow v-if="b1.check == 1" style="font-size: 12px;">
                  <CCol sm="12">
                    <div role="group" class="form-group" style="padding-top: 5px;">
                      <label> Banqueta 2 </label>
                      <select v-model="b2.check" class="form-control" style="font-size: 12px;">
                          <option value="1">SI</option>
                          <option value="0">NO</option>
                      </select>
                    </div>
                  </CCol>
                  <CCol sm="6">
                    <CInput
                      label="Largo (metros)"
                      style="padding-top: 5px;"
                      :lazy="false"
                      v-model="b2.length"
                      placeholder=""
                      :disabled="b2.check == 0"
                      />
                  </CCol>
                  <CCol sm="6">
                    <CInput
                      label="Ancho (metros)"
                      style="padding-top: 5px;"
                      :lazy="false"
                      v-model="b2.width"
                      placeholder=""
                      :disabled="b2.check == 0"
                      />
                  </CCol>
                  <CCol sm="6">
                    <div role="group" class="form-group" style="padding-top: 5px;">
                      <label> Rampas </label>
                      <select v-model="b2.ramps" class="form-control" :disabled="b2.check == 0">
                          <option value="1">SI</option>
                          <option value="0">NO</option>
                      </select>
                    </div>
                  </CCol>
                  <CCol sm="6">
                    <div role="group" class="form-group" style="padding-top: 5px;">
                      <label> Estado </label>
                      <select v-model="b2.status" class="form-control" :disabled="b2.check == 0">
                          <option value="bueno">BUENO</option>
                          <option value="regular">REGULAR</option>
                          <option value="malo">MALO</option>
                      </select>
                    </div>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </CForm>
        </CCol>
      </CRow>
      <CRow v-if="tab == 'ways'">
        <CCol md="12">
          <CForm>   
            <CRow>
              <CCol md="12">
                <CRow style="font-size: 12px;">
                  <CCol sm="4">
                    <CInput
                      label="Largo (metros)"
                      style="padding-top: 5px;"
                      :lazy="false"
                      v-model="ways.length"
                      placeholder=""
                      />
                  </CCol>
                  <CCol sm="4">
                    <CInput
                      label="Ancho (metros)"
                      style="padding-top: 5px;"
                      :lazy="false"
                      v-model="ways.width"
                      placeholder=""
                      />
                  </CCol>
                  <CCol sm="4">
                    <div role="group" class="form-group" style="padding-top: 5px;">
                      <label> Estado </label>
                      <select v-model="ways.type" class="form-control">
                          <option value="asfalto">Asfalto</option>
                          <option value="concreto">Concreto</option>
                          <option value="adoquin">Adoquín</option>
                          <option value="empedrado">Empedrado</option>
                          <option value="empedrado_cemento">Empedrado / Cemento</option>
                          <option value="terraceria">Terraceria</option>
                      </select>
                    </div>
                  </CCol>
                  <CCol sm="6">
                    <CInput
                      label="Baches (cantidad)"
                      style="padding-top: 5px;"
                      :lazy="false"
                      v-model="ways.holes"
                      placeholder=""
                      />
                  </CCol>
                  <CCol sm="6">
                    <div role="group" class="form-group" style="padding-top: 5px;">
                      <label> Estado </label>
                      <select v-model="ways.status" class="form-control">
                          <option value="bueno">BUENO</option>
                          <option value="regular">REGULAR</option>
                          <option value="malo">MALO</option>
                      </select>
                    </div>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </CForm>
        </CCol>
      </CRow>
      <CRow v-if="tab == 'lamps'">
        <CCol md="12">
          <CForm>   
            <CRow>
              <CCol md="12">
                <CRow style="font-size: 12px;">
                  <CCol sm="6">
                    <CInput
                      label="Luminarias (cantidad)"
                      style="padding-top: 5px;"
                      :lazy="false"
                      v-model="lamps.quantity"
                      placeholder=""
                      />
                  </CCol>
                  <CCol sm="6">
                    <div role="group" class="form-group" style="padding-top: 5px;">
                      <label> Estado </label>
                      <select v-model="lamps.status" class="form-control">
                          <option value="bueno">BUENO</option>
                          <option value="regular">REGULAR</option>
                          <option value="malo">MALO</option>
                      </select>
                    </div>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </CForm>
        </CCol>
      </CRow>
      <CRow v-if="tab == 'trees'">
        <CCol md="12">
          <CForm>   
            <CRow>
              <CCol md="12">
                <CRow style="font-size: 12px;">
                  <CCol sm="6">
                    <CInput
                      label="Arboles (cantidad)"
                      style="padding-top: 5px;"
                      :lazy="false"
                      v-model="trees.quantity"
                      placeholder=""
                      />
                  </CCol>
                  <CCol sm="6">
                    <div role="group" class="form-group" style="padding-top: 5px;">
                      <label> Estado </label>
                      <select v-model="trees.status" class="form-control">
                          <option value="bueno">BUENO</option>
                          <option value="regular">REGULAR</option>
                          <option value="malo">MALO</option>
                      </select>
                    </div>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </CForm>
        </CCol>
      </CRow>
      <CRow v-if="tab == 'waters'">
        <CCol md="12">
          <CForm>   
            <CRow>
              <CCol md="12">
                <CRow style="font-size: 12px;">
                  <CCol sm="4">
                    <div role="group" class="form-group" style="padding-top: 5px;">
                      <label> Material </label>
                      <select v-model="waters.type" class="form-control">
                          <option value="asbesto">Asbesto</option>
                          <option value="pvc">PVC</option>
                          <option value="pead">Pead</option>
                      </select>
                    </div>
                  </CCol>
                  <CCol sm="4">
                    <CInput
                      label="Diametro (cantidad)"
                      style="padding-top: 5px;"
                      :lazy="false"
                      v-model="waters.diameters"
                      placeholder=""
                      />
                  </CCol>
                  <CCol sm="4">
                    <div role="group" class="form-group" style="padding-top: 5px;">
                      <label> Estado </label>
                      <select v-model="waters.status" class="form-control">
                          <option value="bueno">BUENO</option>
                          <option value="regular">REGULAR</option>
                          <option value="malo">MALO</option>
                      </select>
                    </div>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </CForm>
        </CCol>
      </CRow>
      <CRow v-if="tab == 'sewers'">
        <CCol md="12">
          <CForm>   
            <CRow>
              <CCol md="12">
                <CRow style="font-size: 12px;">
                  <CCol sm="4">
                    <div role="group" class="form-group" style="padding-top: 5px;">
                      <label> Material </label>
                      <select v-model="sewers.type" class="form-control">
                          <option value="asbesto">Asbesto</option>
                          <option value="pvc">PVC</option>
                          <option value="pead">Pead</option>
                      </select>
                    </div>
                  </CCol>
                  <CCol sm="4">
                    <CInput
                      label="Diametro (cantidad)"
                      style="padding-top: 5px;"
                      :lazy="false"
                      v-model="sewers.diameters"
                      placeholder=""
                      />
                  </CCol>
                  <CCol sm="4">
                    <div role="group" class="form-group" style="padding-top: 5px;">
                      <label> Estado </label>
                      <select v-model="sewers.status" class="form-control">
                          <option value="bueno">BUENO</option>
                          <option value="regular">REGULAR</option>
                          <option value="malo">MALO</option>
                      </select>
                    </div>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </CForm>
        </CCol>
      </CRow>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton @click="store" :color="color">Guardar Cambios</CButton>
        </template>
    </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength, email, sameAs, helpers } from "vuelidate/lib/validators"
import roads from '../../services/roads';
import store from '../../store'

export default {
  name: 'AddSectionModal',
  props: {

  },
  data () {
    return {
      roadModal: false,
      title: "Nuevo Tramo",
      color: "info",
      isEdit: false,
      form: this.getEmptyForm(),
      submitted: false,
      fields: [
        {key: 'code', label: ''},
        {key: 'name', label: ''},
        {key: 'status', label: ''}
      ],
      tab: "info",
      roads: [],
      roadOptions: [],
      stroke: false,
      form: {
        name: '',
        road_id: '',
        road_name: '',
        status: 'regular'
      },
      road: false,
      b1: {
        check: 1,
        length: "",
        width: "",
        status: "bueno",
        ramps: 0
      },
      b2: {
        check: 0,
        length: "",
        width: "",
        status: "bueno",
        ramps: 0
      },
      ways: {
        length: "",
        width: "",
        type: "asfalto",
        status: "bueno",
        holes: ""
      },
      lamps: {
        quantity: "",
        status: "bueno"
      },
      trees: {
        quantity: "",
        status: "bueno"
      },
      waters: {
        type: "asbesto",
        diameters: "",
        status: "bueno"
      },
      sewers: {
        type: "asbesto",
        diameters: "",
        status: "bueno"
      },
      points: false
    }
  },
  mounted: async function() {
    let response = await roads.get(); 

    if(response.type == "success"){
      this.roads = response.data;      
      this.roadOptions = await this.$parseSelectOptionsOrdered(this.roads, "id", "code:name", [{"label": "","value":""}]);
    }
  },
  methods: {
    async openModal (points) {
      this.roadModal = true;
      this.color = "info";
      this.tab = "info";

      this.b1 = {
        check: 1,
        length: "",
        width: "",
        ramps: "",
        status: "bueno",
      }

      this.b2 = {
        check: 0,
        length: "",
        width: "",
        ramps: "",
        status: "bueno",
      }

      this.ways = {
        type: "asfalto",
        length: "",
        width: "",
        holes: "",
        status: "bueno",
      }

      this.lamps = {
        quantity: "",
        status: "bueno",
      }

      this.trees = {
        quantity: "",
        status: "bueno",
      }

      this.waters = {
        type: "asbesto",
        diameters: "",
        status: "bueno",
      }

      this.sewers = {
        type: "asbesto",
        diameters: "",
        status: "bueno",
      }

      this.points = points;

      this.isEdit = false;
      this.submitted = false;
    },
    changeTab (tab) {
      if(this.tab != tab){
        this.tab = tab;
      }
    },
    async changeRoad () {
      this.roads.forEach(async (road) => {
        if(road.id == this.form.road_id){
          this.form.name = road.next_section;
        }
      });
    },
    updateModal (item) {
      this.roadModal = true;
      this.color = "warning";
      this.title = 'Editar Vialidad';
      this.isEdit = true;
      this.submitted = false;

      this.form = {
        name: '',
        road_id: '',
        road_name: '',
        status: 'regular'
      };
    },
    async store () {
      this.$v.$touch();

      if (this.isValid) {
        this.submitted = true;

        let data = {
          stroke_id: 0,
          section_id: 0,
          road_id: this.form.road_id,
          name: this.form.name,
          status: this.form.status,
          b1: this.b1,
          b2: this.b2,
          ways: this.ways,
          lamps: this.lamps,
          trees: this.trees,
          waters: this.waters,
          sewers: this.sewers,
          points: this.points
        }

        let response = await roads.addStroke(data);

        if(response.type == "success"){
          
        }

        this.showToast(response.type, response.message);

        this.closeModal();
      }
    },
    closeModal () {
      this.roadModal = false;

      this.form = this.getEmptyForm()
      this.submitted = false
      this.$v.$reset()
    },
    getEmptyForm () {
      return {
        name: '',
        road_id: '',
        road_name: '',
        status: 'regular'
      }
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid || field.$model === '')
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      name: {
        required
      },
      road_id: {
        required
      }
    }
  },
}
</script>