<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CButton color="info" size="sm" @click="addSection()" style="float: right;">Mapear</CButton><br /><br />
        <div id="map" :style="'border: 1px solid; width: 100%; height: '+(height - 350)+'px;'"></div>
      </CCol>
    </CRow>
    <CaptureModal ref="captureModal"></CaptureModal>
    <AddSectionModal ref="addSectionModal"></AddSectionModal>
  </div>
</template>

<script>
import CaptureModal from '../../components/roads/CaptureModal.vue'
import AddSectionModal from '../../components/roads/AddSectionModal.vue'
import roads from '../../services/roads';
import store from '../../store'
import { Loader } from '@googlemaps/js-api-loader';

export default {
  name: 'Capture',
  components: { CaptureModal, AddSectionModal },
  data: function () {
		return {
            map_kml: "https://s3.us-west-2.amazonaws.com/gob.ameca.mx/66ad59e9763b48395.kml",
            latitude: 0,
            longitude: 0,
            map: false,
            line: false,
            nextV: 1,
            points: false,
            height: 1000
        }
  },
  mounted: async function() {
    this.loading();

    let response = await roads.map("capture");

    if(response.type == "success"){
      this.map_kml = response.data;
    }

    const apiOptions = {
			apiKey: "AIzaSyBjRSxI62T4CTN4Jl_R-1PJoIl2cAo2pAA"
		}

    this.height = window.screen.height;

		const loader = new Loader(apiOptions);

		if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          //this.latitude = position.coords.latitude;
          //this.longitude = position.coords.longitude;

          this.latitude = 20.5551635;
          this.longitude = -104.0526953;

          loader.load().then(() => {
            this.displayMap();
          });
        },
        (error) => {
          console.log(error);
          alert("Error getting user location:", error);
        }
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }

    this.loaded();
  },
  methods: {
    async openCaptureModal(data){
      this.$refs.captureModal.openModal(data);
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    },
    addSection(){
      let _this = this;
      this.map.addListener('click', function(e) {
        _this.startSection(e.latLng);
      });

      this.map.addListener('mousemove', function(e) {
        if (_this.line && _this.line.getPath() && _this.line.getPath().getLength() > (_this.nextV - 1))
          _this.line.getPath().setAt(_this.nextV, e.latLng);
      })
    },
    startSection(latLng) {
      let _this = this;

      this.line = new google.maps.Polyline({
        draggable: true,
        editable: true,
        strokeColor: '#ffff00',
        strokeOpacity: 0.8,
        strokeWeight: 8
      });

      this.nextV = 1;
      this.line.setMap(this.map);
      this.line.getPath().push(latLng);

      this.line.addListener('click', function(e) {
        _this.nextV++;
      })

      this.line.addListener('dblclick', function(e) {
        google.maps.event.clearListeners(_this.map, "mousemove");
        _this.finishSection();
      });

      google.maps.event.clearListeners(this.map, 'click');
    },
    finishSection(){
      this.points = this.line.getPath().getArray();

      google.maps.event.clearListeners(this.line, 'click');
      google.maps.event.clearListeners(this.line, 'mousemove');

      google.maps.event.clearListeners(this.map, 'click');
      google.maps.event.clearListeners(this.map, 'mousemove');

      this.line = false;

      this.$refs.addSectionModal.openModal(this.points);
    },
    displayMap: async function() {
			this.loading();

      let mapOptions = {
        center: {lat: this.latitude, lng: this.longitude},
        zoom: 18,
        mapTypeId: 'satellite'
      };

      let mapDiv = document.getElementById('map');
      this.map = new google.maps.Map(mapDiv, mapOptions);

      const mapLayer = new google.maps.KmlLayer({
				url: this.map_kml.kml_file, 
				map: this.map,
			});

      let _this = this;

      google.maps.event.addListener(mapLayer, 'click', function(kmlEvent) {
        var data = kmlEvent.featureData;

        _this.openCaptureModal(data.description);

        // does not do a thing
        kmlEvent.cancelBubble = true;
    
        // does not exist on the passed in event object
        kmlEvent.stopPropagation && kmlEvent.stopPropagation();
    
        // does not exist on the passed in event object
        kmlEvent.preventDefault && kmlEvent.preventDefault();

        //Se mete un error de javascript para que no saque la burbuja.
        kmlEvent.stop();

        return;
      });

      this.loaded();
		},
    placeMarker: async function(location) {
      var marker = new google.maps.Marker({
          position: location, 
          map: this.map
      });
    }
  }
}
</script>
